import {__module} from '../modules';
import Popup from '../lib/popup';

__module('popup',  (el, options) => {


  $(el).click(function(){
    const pup = new Popup();
    const thumb = $('<div></div>');
    const element = $($(el).attr('data-element'));
    const url = $(el).attr('data-url');
    if(url){
      thumb.load(url)
    }
    thumb.find('[data-role=close-popup]').click(()=>{
      pup.close();
    })
    pup.open(thumb)
  })

})