'use strict';

/**
 * @return {undefined}
 */
function openMobileDropdown() {
  $(".js-has-dropdown > a").off("click").on("click", function(event) {
    event.preventDefault();
    var thisText = $(this).text().trim();
    $(".site-nav-title").append("<span> &raquo; " + thisText + "</span>");
    $(this).next(".nav-dropdown, .nav-dropdown-submenu").fadeIn();
    $(this).next().addClass("nav-dropdown-active");
    $(this).closest(".site-nav-level").addClass("nav-invisible");
  });
}
/**
 * @return {undefined}
 */
function closeMobileDropdown() {
  $(".js-close-dropdown").off("click").on("click", function(event) {
    event.preventDefault();
    $(".site-nav-title").find("span:last-child").remove();
    $(this).closest(".nav-dropdown-active").removeClass("nav-dropdown-active").fadeOut();
    $(this).closest(".nav-invisible").removeClass("nav-invisible");
  });
}
/**
 * @return {undefined}
 */
let hovered;
function toggleDesktopDropdown() {
  $(".js-desktop-dropdown").hover(function() {
    var element = $(this);
    /** @type {boolean} */
    hovered = true;
    setTimeout(function() {
      if (hovered) {
        element.addClass("desktop-dropdown-active");
        element.find(".nav-dropdown").trigger("parentClassChanged");
        element.find(".nav-dropdown").stop().slideDown(200);
      }
    }, 250);
  }, function() {
    var $optionsMenu = $(this);
    /** @type {boolean} */
    hovered = false;
    if (!hovered) {
      setTimeout(function() {
        $optionsMenu.find(".nav-dropdown").stop().slideUp(200);
        $optionsMenu.removeClass("desktop-dropdown-active");
      }, 250);
    }
  });
}
/**
 * @return {undefined}
 */
function mainNav() {
  $(".js-has-dropdown > a, .js-close-dropdown").off("click");
  $(".js-desktop-dropdown").off("mouseenter mouseleave");
  if ($(window).width() < 992) {
    $(".nav-dropdown").css({
      "margin-top" : "",
      "margin-bottom" : "",
      "padding-top" : "",
      "padding-bottom" : "",
      "height" : ""
    });
    openMobileDropdown();
    closeMobileDropdown();
  } else {
    $("body").removeClass("nav-active overflow-hidden");
    $(".nav-dropdown-active").removeClass("nav-dropdown-active");
    $(".nav-invisible").removeClass("nav-invisible");
    $(".nav-dropdown-submenu").removeAttr("style");
    if (!$("body").hasClass("nav-active")) {
      $(".nav-overlay").hide();
    }
    toggleDesktopDropdown();
  }
}
/**
 * @return {undefined}
 */
function headerDropdownsHover() {
  if ($(window).width() >= 992) {
    $(".js-header-dropdown-hover").off("mouseenter").on("mouseenter", function() {
      $(this).find(".header-dropdown").css("height", "");
      $(this).addClass("header-dropdown-active");
      $(this).find(".header-dropdown").stop().slideDown(200);
    }).off("mouseleave").on("mouseleave", function() {
      $(this).removeClass("header-dropdown-active");
      $(this).find(".header-dropdown").stop().slideUp(200);
    });
  } else {
    $(".js-header-dropdown-hover").off("mouseenter mouseleave click").on("click", function(event) {
      event.preventDefault();
      $(this).toggleClass("header-dropdown-active");
      $(this).find(".header-dropdown").stop().slideToggle(200);
    });
  }
}


/** @type {number} */
var lastScrollTop = 0;
/**
 * @return {undefined}
 */
function addHeaderScrolledClass() {
  var currentScrollTop = $(window).scrollTop();
  if (currentScrollTop != 0) {
    $(".site-header").addClass("header-scrolled");
  } else {
    $(".site-header").removeClass("header-scrolled");
  }
  if ($(window).scrollTop() > 120) {
    $(".site-header").addClass("header-small");
  } else {
    $(".site-header").removeClass("header-small");
  }
  if (currentScrollTop < lastScrollTop) {
    $(".site-header").addClass("scrolled-top").removeClass("scrolled-down");
  } else {
    $(".site-header").removeClass("scrolled-top").addClass("scrolled-down");
  }
  lastScrollTop = currentScrollTop;
}





$(window).on("scroll", function() {
  addHeaderScrolledClass();
});
$(window).on("resize", function() {
  mainNav();
  headerDropdownsHover();
  if ($(window).width() > 992) {
    $(".search-container").css("display", "");
  }
});
$(document).ready(function() {
  /**
   * @return {undefined}
   */



  mainNav();
  addHeaderScrolledClass();
  headerDropdownsHover();


  $(".js-btn-nav-close").on("click", function() {
    hideDisqussion();
  });
  $(".js-nav-overlay").on("click", function() {
    hideDisqussion();
  });   
  function hideDisqussion() {
    $("body").removeClass("nav-active overflow-hidden");
    $(".nav-overlay").fadeOut(200);
    closeMobileDropdown();
  }

  $(".js-btn-nav-open").on("click", function() {
    $("body").addClass("nav-active overflow-hidden");
    $(".nav-overlay").fadeIn(200);
  });


  $(".js-header-dropdown-toggle").on("click", function() {
    $(this).parent().toggleClass("header-dropdown-active");
    $(this).next().slideToggle(200);
  });

  $(".has-ripple, .links-have-ripple a").on("mousedown", function(event) {
    event.stopPropagation();
    var mask = $("<div/>");
    /** @type {number} */
    var _ileft = event.pageX - $(this).offset().left;
    /** @type {number} */
    var tabPadding = event.pageY - $(this).offset().top;
    /** @type {number} */
    var duration = 1.2;
    mask.addClass("ripple-effect");
    mask.css({
      "animation-duration" : duration + "s",
      width : $(this).width() * 1.5,
      height : $(this).width() * 1.5,
      top : tabPadding,
      left : _ileft
    }).appendTo($(this));
    window.setTimeout(function() {
      mask.remove();
    }, duration * 1000 - 100);
  });



});





