import {__module} from '../modules';

import {
    jarallax,
    // jarallaxElement,
    // jarallaxVideo
} from 'jarallax';

__module('parallax',  (el, options) => {

  // $(el).css({"background": "tranparent"})
  $(document).ready(function() {
    jarallax(el)
  })

})


 