import {__module} from '../modules';

import 'slick-carousel';

__module('slider',  (el, options) => {


  let opts = {
    slidesToShow: 1,
    fade: false,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2500,
  }

  if(options.arrows == "true"){
    opts.arrows = true
  }

  if(options.dots == "true"){
    opts.dots = true
  }


  if(options.multiple == "true"){
    delete opts.slidesToShow;
    opts.fade = false;
    opts.infinite = false;
    // opts.autoSlidesToShow = true;
    opts.variableWidth = true;
  }

  if(options.center == "true"){
    opts.slidesToShow = 3;
    opts.fade = false;
    opts.centerMode = true;
    opts.centerPadding = '60px';
    opts.responsive = [
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      },
    ]
  }
  $(el).slick(opts)

})