'use strict';
var App = {
  init : function() {
    this.mobMenu.init();
    this.cookie.init();
    this.alert.init();
    this.popup.init();
    this.forms.init();
    this.sliders.init();
    this.accordion.init();
    this.photogallery.init();
    this.mainDoctors();
    this.services.init();
    this.sticky.init();
    this.upBtn.init();
    this.tabs.init();
    this.fixHeightOnSubmit();
    this.anchorMenu.init();
    // this.map.init();
    // if ($(window).width() > 767) {
    //   if (device.ipad() || device.tablet()) {
    //     return false;
    //   } else {
    //     skrollr.init();
    //   }
    // }
  },
  map : {
    init : function() {
      App.map.simpleMap();
      App.map.simpleMapYandex();
      App.map.howToGet();
      App.map.howToGetYandex();
    },
    styles : [{
      "featureType" : "landscape",
      "elementType" : "all",
      "stylers" : [{
        "hue" : "#F1FF00"
      }, {
        "saturation" : -27.4
      }, {
        "lightness" : 9.4
      }, {
        "gamma" : 1
      }]
    }, {
      "featureType" : "poi",
      "elementType" : "all",
      "stylers" : [{
        "hue" : "#9fff00"
      }, {
        "gamma" : 1
      }, {
        "visibility" : "off"
      }]
    }, {
      "featureType" : "road.highway",
      "elementType" : "all",
      "stylers" : [{
        "saturation" : -20
      }, {
        "lightness" : 36.4
      }, {
        "gamma" : 1
      }, {
        "color" : "#d2deea"
      }]
    }, {
      "featureType" : "road.highway",
      "elementType" : "labels.text.fill",
      "stylers" : [{
        "color" : "#83929b"
      }]
    }, {
      "featureType" : "road.highway",
      "elementType" : "labels.text.stroke",
      "stylers" : [{
        "visibility" : "off"
      }]
    }, {
      "featureType" : "road.arterial",
      "elementType" : "all",
      "stylers" : [{
        "gamma" : 1
      }, {
        "color" : "#d2deea"
      }]
    }, {
      "featureType" : "road.arterial",
      "elementType" : "labels.text.fill",
      "stylers" : [{
        "color" : "#83929b"
      }]
    }, {
      "featureType" : "road.arterial",
      "elementType" : "labels.text.stroke",
      "stylers" : [{
        "visibility" : "on"
      }]
    }, {
      "featureType" : "road.local",
      "elementType" : "all",
      "stylers" : [{
        "saturation" : -38
      }, {
        "lightness" : 11.2
      }, {
        "gamma" : 1
      }, {
        "color" : "#d2deea"
      }]
    }, {
      "featureType" : "road.local",
      "elementType" : "labels.text.fill",
      "stylers" : [{
        "color" : "#83929b"
      }]
    }, {
      "featureType" : "road.local",
      "elementType" : "labels.text.stroke",
      "stylers" : [{
        "visibility" : "off"
      }]
    }, {
      "featureType" : "transit.station.rail",
      "elementType" : "labels.text",
      "stylers" : [{
        "visibility" : "off"
      }]
    }, {
      "featureType" : "transit.station.rail",
      "elementType" : "labels.icon",
      "stylers" : [{
        "visibility" : "simplified"
      }]
    }, {
      "featureType" : "water",
      "elementType" : "all",
      "stylers" : [{
        "saturation" : 4.2
      }, {
        "lightness" : -63.4
      }, {
        "gamma" : 1
      }, {
        "color" : "#6593c4"
      }]
    }],
    simpleMapYandex : function() {
      /**
       * @return {undefined}
       */
      function init() {
        $(".js--map-yandex").each(function() {
          /**
           * @param {number} x1
           * @return {undefined}
           */
          function init(x1) {
            map.setZoom(x1);
          }
          /**
           * @return {undefined}
           */
          function render() {
            map.setBounds(line.getBounds());
            close(map.getZoom());
          }
          /**
           * @param {number} a
           * @return {undefined}
           */
          function close(a) {
            resolvedSourceMapConfigs.map(function(_target, row) {
              var width = _target.ZOOM_OFFSET_Y;
              if (width != undefined) {
                var filem = _target.COORDS.split(",");
                /** @type {number} */
                var left = Number(filem[0]);
                /** @type {number} */
                var lon = Number(filem[1]);
                if (a <= 12) {
                  /** @type {number} */
                  var scale = 12 - a;
                  switch(scale) {
                    case 1:
                      /** @type {number} */
                      scale = scale * 0.005;
                      break;
                    case 2:
                      /** @type {number} */
                      scale = scale * 0.01;
                      break;
                    case 3:
                      /** @type {number} */
                      scale = scale * 0.02;
                      break;
                  }
                  /** @type {number} */
                  width = Number(width) * scale;
                  /** @type {number} */
                  left = left + width;
                }
                /** @type {!Array} */
                var xPosition = [left, lon];
                self.get(row).geometry.setCoordinates(xPosition);
              }
            });
          }
          var $element = $(this);
          var mapID = $element.attr("data-map-id");
          var map = new ymaps.Map($element[0], {
            center : [59.937, 30.313],
            controls : ["zoomControl"],
            zoom : 12
          }, {
            minZoom : 9
          });
          map.behaviors.disable("scrollZoom");
          /** @type {*} */
          var resolvedSourceMapConfigs = JSON.parse($(this).attr("data-markers"));
          var self = new ymaps.GeoObjectCollection;
          var line = new ymaps.GeoObjectCollection;
          var squareLayout = ymaps.templateLayoutFactory.createClass('<div data-name="{{ properties.name }}" class="{{ properties.class }} {{ properties.activeClass }}"></div>');
          resolvedSourceMapConfigs.map(function(data) {
            var filem = data.COORDS.split(",");
            /** @type {!Array} */
            var center = [Number(filem[0]), Number(filem[1])];
            /** @type {number} */
            var coorditatesSize = 10;
            /** @type {!Array} */
            var userloc = [[-coorditatesSize, -coorditatesSize], [coorditatesSize * 15, coorditatesSize]];
            if ($(window).width() < 768) {
              /** @type {number} */
              coorditatesSize = 17;
              /** @type {!Array} */
              userloc = [[-coorditatesSize, -coorditatesSize], [coorditatesSize, coorditatesSize]];
            }
            if (data.LABEL_POSITION == "left") {
              /** @type {!Array} */
              userloc = [[-coorditatesSize * 15, -coorditatesSize], [coorditatesSize, coorditatesSize]];
            }
            var placemark = new ymaps.Placemark(center, {
              name : data.NAME,
              class : "marker-label-yandex _" + data.LABEL_POSITION + " " + $element.attr("data-label-mod"),
              activeClass : ""
            }, {
              iconLayout : squareLayout,
              iconShape : {
                type : "Rectangle",
                coordinates : userloc
              }
            });
            var vector = new ymaps.Placemark(center, {}, {
              iconLayout : "default#image",
              iconImageHref : "",
              iconImageSize : [1, 1]
            });
            if (data.FIT_BOUNDS != "N") {
              line.add(vector);
            }
            self.add(placemark);
            placemark.events.add("click", function() {
              switch($element.attr("data-marker-action")) {
                case "link":
                  location.href = data.DETAIL_LINK;
                  break;
                case "open-main-infowindow":
                  self.each(function(item) {
                    item.properties.set("activeClass", "");
                  });
                  placemark.properties.set("activeClass", "_active");
                  $(".js--main-map-infowindow").removeClass("_active");
                  $(".js--main-map-infowindow[data-id=" + data.ID + "]").addClass("_active");
                  map.setCenter(center);
                  map.setZoom(16);
                  break;
              }
            });
            $(document).on("click", ".js--main-map-infowindow-close", function(event) {
              event.preventDefault();
              self.each(function(item) {
                item.properties.set("activeClass", "");
              });
              $(".js--main-map-infowindow").removeClass("_active");
              render();
            });
          });
          map.geoObjects.add(line);
          map.geoObjects.add(self);
          render();
          $(document).on("mapFitBounds", function() {
            setTimeout(function() {
              render();
            }, 100);
          });
          if (resolvedSourceMapConfigs.length == 1) {
            init(17);
          }
          $(document).on("change", ".js--map-marker-select[data-map-id=" + mapID + "]", function(canCreateDiscussions) {
            var id = $(this).val();
            if (id == "") {
              $(document).trigger("mapFitBounds");
            }
            resolvedSourceMapConfigs.map(function(server, messages) {
              if (server.ID == id) {
                var filem = server.COORDS.split(",");
                /** @type {!Array} */
                var station_location = [Number(filem[0]), Number(filem[1])];
                map.setCenter(station_location);
                map.setZoom(16);
                self.get(messages).events.fire("click");
              }
            });
          });
          map.events.add("boundschange", function(event) {
            var scale = event.get("newZoom");
            if (scale != event.get("oldZoom")) {
              close(scale);
            }
          });
        });
      }
      ymaps.ready(init);
    },
    simpleMap : function() {
      $(".js--map").each(function() {
        /**
         * @param {number} x1
         * @return {undefined}
         */
        function init(x1) {
          var listener = google.maps.event.addListener(map, "idle", function() {
            map.setZoom(x1);
            google.maps.event.removeListener(listener);
          });
        }
        /**
         * @return {undefined}
         */
        function handleMapMovements() {
          map.fitBounds(bounds);
        }
        var $this = $(this);
        var mapID = $this.attr("data-map-id");
        var bounds = new google.maps.LatLngBounds;
        var map = new google.maps.Map($(this)[0], {
          disableDefaultUI : true,
          zoomControl : true,
          zoomControlOptions : {
            position : google.maps.ControlPosition.LEFT_CENTER
          },
          styles : App.map.styles
        });
        /** @type {*} */
        var resolvedSourceMapConfigs = JSON.parse($(this).attr("data-markers"));
        var subwikiListsCache = resolvedSourceMapConfigs.map(function(data) {
          var filem = data.COORDS.split(",");
          var coords = {
            lat : Number(filem[0]),
            lng : Number(filem[1])
          };
          var scaledSize = new google.maps.Size(16, 19);
          if ($(window).width() < 768) {
            scaledSize = new google.maps.Size(29, 34);
          }
          var i_rrd_data = $this.attr("data-marker-size");
          if (i_rrd_data != undefined) {
            var arr = i_rrd_data.split(",");
            scaledSize = new google.maps.Size(arr[0], arr[1]);
          }
          var self = new MarkerWithLabel({
            map : map,
            position : coords,
            icon : {
              url : "/assets/img/marker@2x.png",
              scaledSize : scaledSize
            },
            labelContent : data.NAME,
            labelClass : "marker-label _" + data.LABEL_POSITION + " " + $this.attr("data-label-mod")
          });
          self.addListener("click", function() {
            switch($this.attr("data-marker-action")) {
              case "link":
                location.href = data.DETAIL_LINK;
                break;
              case "open-main-infowindow":
                subwikiListsCache.map(function(item) {
                  try {
                    var value = item.labelClass.replace("_active", "");
                    if (value != item.labelClass) {
                      item.labelClass = value;
                      item.label.setStyles();
                    }
                  } catch (conv_reverse_sort) {
                    console.log(conv_reverse_sort);
                  }
                });
                $(".js--main-map-infowindow").removeClass("_active");
                $(".js--main-map-infowindow[data-id=" + data.ID + "]").addClass("_active");
                /** @type {string} */
                self.labelClass = "marker-label _active _" + data.LABEL_POSITION;
                self.label.setStyles();
                break;
            }
          });
          $(document).on("click", ".js--main-map-infowindow-close", function(event) {
            event.preventDefault();
            subwikiListsCache.map(function(item) {
              try {
                var value = item.labelClass.replace("_active", "");
                if (value != item.labelClass) {
                  item.labelClass = value;
                  item.label.setStyles();
                }
              } catch (conv_reverse_sort) {
                console.log(conv_reverse_sort);
              }
            });
            $(".js--main-map-infowindow").removeClass("_active");
          });
          if (data.FIT_BOUNDS != "N") {
            bounds.extend(coords);
          }
          return self;
        });
        handleMapMovements();
        $(document).on("mapFitBounds", function() {
          handleMapMovements();
        });
        if (resolvedSourceMapConfigs.length == 1) {
          init(17);
        }
        $(document).on("change", ".js--map-marker-select[data-map-id=" + mapID + "]", function(canCreateDiscussions) {
          var id = $(this).val();
          if (id == "") {
            map.fitBounds(bounds);
          }
          resolvedSourceMapConfigs.map(function(server, wikiId) {
            if (server.ID == id) {
              var filem = server.COORDS.split(",");
              var center = {
                lat : Number(filem[0]),
                lng : Number(filem[1])
              };
              map.setCenter(center);
              map.setZoom(16);
              new google.maps.event.trigger(subwikiListsCache[wikiId], "click");
            }
          });
        });
      });
      $(document).on("click", ".js--map-fit-bounds", function() {
        setTimeout(function() {
          $(document).trigger("mapFitBounds");
        }, 500);
        $(this).removeClass("js--map-fit-bounds");
      });
    },
    howToGetYandex : function() {
      $(".js--map-how-to-get-yandex").each(function() {
        /**
         * @return {undefined}
         */
        function createRoute() {
          var multiRoute = new ymaps.multiRouter.MultiRoute({
            referencePoints : referencePoints,
            params : {
              routingMode : "pedestrian"
            }
          }, {
            boundsAutoApply : true
          });
          var myMap = new ymaps.Map($element[0], {
            center : [55.739625, 37.54120],
            zoom : 12,
            controls : ["zoomControl"]
          }, {});
          myMap.geoObjects.add(multiRoute);
        }
        var $element = $(this);
        /** @type {*} */
        var referencePoints = JSON.parse($element.attr("data-coords"));
        referencePoints = referencePoints.map(function(machine) {
          return machine.NAME;
        });
        ymaps.ready(createRoute);
      });
    },
    howToGet : function() {
      $(".js--map-how-to-get").each(function() {
        /**
         * @return {undefined}
         */
        function init() {
          /** @type {*} */
          var opargs = JSON.parse($element.attr("data-coords"));
          opargs = opargs.map(function(machine) {
            return machine.NAME;
          });
          /** @type {!Array} */
          var started = [];
          var attrs = new google.maps.DirectionsService;
          var noop = new google.maps.Map($element[0], {
            disableDefaultUI : true,
            zoomControl : true,
            zoomControlOptions : {
              position : google.maps.ControlPosition.LEFT_CENTER
            },
            styles : App.map.styles
          });
          var _this = new google.maps.DirectionsRenderer({
            map : noop
          });
          var cb = new google.maps.InfoWindow;
          start(_this, attrs, started, cb, noop, opargs);
        }
        /**
         * @param {?} renderer
         * @param {!Object} map
         * @param {!Array} obj
         * @param {!Object} item
         * @param {string} cb
         * @param {!Object} args
         * @return {undefined}
         */
        function start(renderer, map, obj, item, cb, args) {
          /** @type {number} */
          var i = 0;
          for (; i < obj.length; i++) {
            obj[i].setMap(null);
          }
          map.route({
            origin : args[0],
            destination : args[1],
            travelMode : "WALKING"
          }, function(response, data) {
            if (data === "OK") {
              renderer.setDirections(response);
              render(response, obj, item, cb);
            } else {
              window.alert("Directions request failed due to " + data);
            }
          });
        }
        /**
         * @param {!Object} modules
         * @param {!Array} profile
         * @param {!Object} data
         * @param {string} width
         * @return {undefined}
         */
        function render(modules, profile, data, width) {
          var unitDefinition = modules.routes[0].legs[0];
          /** @type {number} */
          var i = 0;
          for (; i < unitDefinition.steps.length; i++) {
            var m = profile[i] = profile[i] || new google.maps.Marker;
            m.setMap(width);
            m.setPosition(unitDefinition.steps[i].start_location);
            addMarker(data, m, unitDefinition.steps[i].instructions, width);
          }
        }
        /**
         * @param {!Object} obj
         * @param {?} marker
         * @param {?} data
         * @param {string} val
         * @return {undefined}
         */
        function addMarker(obj, marker, data, val) {
          google.maps.event.addListener(marker, "click", function() {
            obj.setContent(data);
            obj.open(val, marker);
          });
        }
        var $element = $(this);
        init();
      });
    }
  },
  mainDoctors : function() {
    $(".js--main-doctors-slider").slick({
      prevArrow : $(".js--main-doctors-slider-prev"),
      nextArrow : $(".js--main-doctors-slider-next"),
      infinite : false,
      slidesToShow : 3
    });
  },
  reviews : function() {
    /**
     * @return {undefined}
     */
    function callback() {
      $(arrowDiv).css({
        "minHeight" : $(breadPrmySel + "._active").innerHeight()
      });
    }
    /** @type {string} */
    var CLASS_TAB = ".js--main-review-tab-nav";
    /** @type {string} */
    var breadPrmySel = ".js--main-review-tab-item";
    /** @type {string} */
    var arrowDiv = ".js--main-review-tabs";
    callback();
    $(document).on("click", CLASS_TAB, function(event) {
      event.preventDefault();
      $(CLASS_TAB).removeClass("_active");
      $(this).addClass("_active");
      $(breadPrmySel).removeClass("_active");
      $(breadPrmySel + "[data-id=" + $(this).attr("data-id") + "]").addClass("_active");
      callback();
    });
  },
  cookie : {
    init : function() {
      $(document).on("click", ".js--cookie-close", function(event) {
        event.preventDefault();
        App.cookie.close();
      });
    },
    open : function() {
      $(".js--cookie-alert-wrapper").addClass("_active");
    },
    close : function() {
      $(".js--cookie-alert-wrapper").removeClass("_active");
    }
  },
  alert : {
    allertClass : ".js--alert",
    init : function() {
      $(document).on("click", ".js--alert-close", function(event) {
        event.preventDefault();
        App.alert.close();
      });
    },
    open : function(x) {
      $(App.alert.allertClass + " .alert__title").text(x.title);
      $(App.alert.allertClass + " .alert__text").text(x.text);
      $(App.alert.allertClass).slideDown(300);
    },
    close : function(type, cmp) {
      $(App.alert.allertClass).slideUp(300);
      setTimeout(function() {
        $(App.alert.allertClass + " .alert__title").text("");
        $(App.alert.allertClass + " .alert__text").text("");
      }, 300);
    }
  },
  popup : {
    options : {
      baseClass : "popup",
      btnTpl : {
        smallBtn : '<a href="javascript:void(0);" class="popup__close" data-fancybox-close></a>'
      },
      autoFocus : false,
      backFocus : false,
      trapFocus : false,
      touch : false,
      beforeLoad : function(event) {
        $("body").addClass("fix-body");
      },
      afterLoad : function(viewer, options) {
        App.forms.autocomplete($(options.src));
        $(document).trigger("mapFitBounds");
      },
      afterClose : function() {
        $("body").removeClass("fix-body");
      }
    },
    init : function() {
      try {
        $(".js--popup").fancybox(App.popup.options);
      } catch (conv_reverse_sort) {
        console.log(conv_reverse_sort);
      }
    },
    open : function(x) {
      $.fancybox.open({
        src : x,
        opts : App.popup.options
      });
    },
    openSuccess : function(options) {
      $(".js--success-popup-title").html(options.title);
      $(".js--success-popup-text").html(options.text);
      if (options.btnText) {
        $(".js--success-popup-btn").html(options.btnText);
      }
      console.log("openSuccess - $.fancybox", $.fancybox);
      console.log("openSuccess - jQuery", jQuery);
      console.log("openSuccess - window.jQuery", window.jQuery);
      (function($) {
        console.log("openSuccess - $.fancybox 2", $.fancybox);
        $.fancybox.open({
          src : "#success-popup",
          opts : App.popup.options
        });
      })(jQuery);
    },
    openError : function(options) {
      $(".js--error-popup-title").html(options.title);
      $(".js--error-popup-text").html(options.text);
      if (options.btnText) {
        $(".js--error-popup-btn").html(options.btnText);
      }
      $.fancybox.open({
        src : "#error-popup",
        opts : App.popup.options
      });
    }
  },
  forms : {
    init : function() {
      App.forms.autocomplete();
      App.forms.checkboxFilter();
      // App.forms.validate();
      App.forms.reset();
      $(document).on("blur", "input, textarea", function() {
        App.forms.toggleLabel($(this));
      });
      $(document).on("focus", "input, textarea", function() {
        $(this).closest(".form-item").addClass("_not-empty");
      });
      $(document).on("change", "select", function() {
        App.forms.toggleLabel($(this));
      });
      $("input, textarea, select").each(function() {
        App.forms.toggleLabel($(this));
      });
    },
    toggleLabel : function($this) {
      var primaryExtension = $this.val();
      var $allPanels = $this.closest(".form-item");
      if (primaryExtension != "") {
        $allPanels.addClass("_not-empty");
      } else {
        $allPanels.removeClass("_not-empty");
      }
    },
    autocomplete : function(data) {
      if (!data && data == "undefined") {
        data = $("body");
      }
      $(".js--input-autocomplete", data).each(function() {
        var $input = $(this);
        $input.easyAutocomplete({
          data : JSON.parse($input.attr("data-values")),
          getValue : "item",
          list : {
            match : {
              enabled : true
            },
            onChooseEvent : function() {
              if ($input.hasClass("js--autocomplete-link")) {
                var loc = $input.getSelectedItemData().href;
                location.href = loc;
              }
              var $conditionsRuleMajor = $(".js--input-autocomplete-hidden[data-autocomplete-id=" + $input.attr("data-autocomplete-id") + "]");
              $conditionsRuleMajor.val($input.getSelectedItemData().value);
            }
          }
        });
      });
    },
    checkboxFilter : function() {
      /**
       * @param {!Object} $container
       * @return {?}
       */
      function closeDropdownsIn($container) {
        if ($container.hasClass(w.substr(1))) {
          return true;
        } else {
          return false;
        }
      }
      /**
       * @param {!Object} elem
       * @return {?}
       */
      function wdtimer_saveProfile(elem) {
        if (elem.prop("checked")) {
          return true;
        } else {
          return false;
        }
      }
      /** @type {string} */
      var selector = ".js--checkbox-filter";
      /** @type {string} */
      var w = ".js--checkbox-filter-all";
      $(document).on("change", selector + " input[type=checkbox]", function() {
        var elem = $(this).closest(selector);
        var btn = $(w, elem);
        if (!closeDropdownsIn($(this)) && wdtimer_saveProfile($(this))) {
          btn.prop("checked", false);
        }
        if (closeDropdownsIn($(this)) && wdtimer_saveProfile($(this))) {
          $("input[type=checkbox]:not(" + w + ")", elem).prop("checked", false);
        }
        if (!$("input[type=checkbox]:checked", elem).length) {
          btn.prop("checked", true);
        }
      });
    },
    // validate : function() {
    //   $("form.js--validate").each(function() {
    //     $(this).validate({
    //       errorClass : "_error",
    //       errorPlacement : function(error, element) {
    //         $(element).closest(".form-item").find(".form-item__error").text(error.text());
    //       }
    //     });
    //   });
    // },
    reset : function() {
      /**
       * @param {string} textureVel
       * @return {undefined}
       */
      function render(textureVel) {
        /** @type {boolean} */
        var flag = false;
        $("[data-reset-id=" + textureVel + "]").each(function() {
          if ($(this).val() != "") {
            /** @type {boolean} */
            flag = true;
          }
        });
        if (flag) {
          $("[data-id=" + textureVel + "]").addClass("_active");
        } else {
          $("[data-id=" + textureVel + "]").removeClass("_active");
        }
      }
      $(document).on("input change", "[data-reset-id]", function() {
        render($(this).attr("data-reset-id"));
      });
      $(document).on("click", "[type=reset][data-id]", function(canCreateDiscussions) {
        var item = $(this);
        var _td_h = item.attr("data-id");
        item.removeClass("_active");
        setTimeout(function() {
          $("[data-reset-id=" + _td_h + "]").each(function() {
            App.forms.toggleLabel($(this));
          });
        }, 100);
      });
    }
  },
  sliders : {
    init : function($element) {
      if ($element == undefined) {
        $element = $("body");
      }
      $(".js--slider-block", $element).each(function() {
        let slidesToShowTablet, slidesToShowTabletMin, slidesToShowMobile, adaptiveHeightMobile, adaptiveHeight;
        var toolsTable = $(this);
        var elem = $(".js--slider", $(this));
        if (elem.hasClass("_init")) {
          elem.removeClass("_init").slick("unslick");
        }
        /** @type {number} */
        var parsedVal = 4;
        var val = elem.attr("data-count");
        if (val != undefined) {
          /** @type {number} */
          parsedVal = Number(val);
        }
        var additionalDigits = elem.attr("data-count-tablet");
        if (additionalDigits != undefined) {
          /** @type {number} */
          slidesToShowTablet = Number(additionalDigits);
        } else {
          /** @type {number} */
          slidesToShowTablet = parsedVal;
        }
        var bulbID = elem.attr("data-count-tablet-min");
        if (bulbID != undefined) {
          /** @type {number} */
          slidesToShowTabletMin = Number(bulbID);
        } else {
          /** @type {number} */
          slidesToShowTabletMin = slidesToShowTablet;
        }
        var minWorkers = elem.attr("data-count-mobile");
        if (minWorkers != undefined) {
          /** @type {number} */
          slidesToShowMobile = Number(minWorkers);
        } else {
          /** @type {number} */
          slidesToShowMobile = slidesToShowTabletMin;
        }
        var i_rrd_data = elem.attr("data-adaptive-height");
        if (i_rrd_data != undefined) {
          /** @type {boolean} */
          adaptiveHeight = true;
        } else {
          /** @type {boolean} */
          adaptiveHeight = false;
        }
        var relid1 = elem.attr("data-adaptive-height-mobile");
        if (relid1 != undefined) {
          /** @type {boolean} */
          adaptiveHeightMobile = true;
        } else {
          /** @type {boolean} */
          adaptiveHeightMobile = adaptiveHeight;
        }
        elem.on("init", function() {
          elem.addClass("_init");
          $(".js--slider-val", toolsTable).text("01");
        }).slick({
          prevArrow : $(".js--slider-prev", toolsTable),
          nextArrow : $(".js--slider-next", toolsTable),
          infinite : false,
          slidesToShow : parsedVal,
          adaptiveHeight : adaptiveHeight,
          responsive : [{
            breakpoint : 1025,
            settings : {
              slidesToShow : slidesToShowTablet
            }
          }, {
            breakpoint : 993,
            settings : {
              slidesToShow : slidesToShowTabletMin
            }
          }, {
            breakpoint : 768,
            settings : {
              slidesToShow : slidesToShowMobile,
              adaptiveHeight : adaptiveHeightMobile
            }
          }]
        }).on("afterChange", function(boardManager, event, canCreateDiscussions) {
          var tools = $(".js--slider-val", toolsTable);
          if (tools.length) {
            var gpx_str = event.currentSlide + 1;
            if (gpx_str < 10) {
              /** @type {string} */
              gpx_str = "0" + gpx_str;
            }
            $(".js--slider-val", toolsTable).text(gpx_str);
          }
        });
      });
    }
  },
  accordion : {
    class : {
      toggler : ".js--accordion-toggler",
      body : ".js--accordion-body",
      block : ".js--accordion"
    },
    init : function() {
      $(document).on("click", App.accordion.class.toggler, function(event) {
        event.preventDefault();
        var $realtime = $(this);
        var item = $(this).closest(App.accordion.class.block);
        var squanchSlider = $(App.accordion.class.body, item);
        if (!item.hasClass("_active")) {
          item.addClass("_active");
          squanchSlider.slideDown(300);
        } else {
          item.removeClass("_active");
          squanchSlider.slideUp(300);
        }
      });
    }
  },
  tabs : {
    init : function() {
      $(".js--tabs").each(function() {
        var i_rrd_data = $(this).attr("data-only-mobile");
        if (i_rrd_data != undefined && $(window).width() >= 768) {
          return;
        }
        $(this).easytabs({
          tabs : ".js--tabs-item",
          tabActiveClass : "_active",
          defaultTab : $(".js--tabs-item", $(this)).first(),
          updateHash : false,
          animate : false
        }).bind("easytabs:after", function(event, canCreateDiscussions, $table, isSlidingUp) {
          App.sliders.init($table);
        });
      });
    }
  },
  photogallery : {
    class : {
      block : ".js--photogallery",
      big : ".js--photogallery-big",
      min : ".js--photogallery-min",
      prev : ".js--photogallery-prev",
      next : ".js--photogallery-next",
      minItem : ".js--photogallery-min-item"
    },
    init : function() {
      $(App.photogallery.class.block).each(function() {
        var $slider_for = $(App.photogallery.class.big, $(this));
        var $slider_nav = $(App.photogallery.class.min, $(this));
        var _prev = $(App.photogallery.class.prev, $(this));
        var _next = $(App.photogallery.class.next, $(this));
        /** @type {number} */
        var slidesToShow = 3;
        if ($(this).hasClass("_grid-2")) {
          /** @type {number} */
          slidesToShow = 2;
        }
        /** @type {number} */
        var rows = 2;
        if ($(this).hasClass("_grid-2")) {
          /** @type {number} */
          rows = 4;
        }
        $slider_for.slick({
          slidesToShow : 1,
          fade : true,
          arrows : false
        });
        $slider_nav.slick({
          slidesToShow : slidesToShow,
          rows : rows,
          prevArrow : _prev,
          nextArrow : _next,
          infinite : false,
          responsive : [{
            breakpoint : 768,
            settings : {
              slidesToShow : 3,
              rows : 2
            }
          }]
        });
      });
      $(document).on("click", App.photogallery.class.minItem, function(event) {
        event.preventDefault();
        var item = $(this).attr("data-id");
        var master_settings_element = $(this).closest(App.photogallery.class.block);
        var options = $(App.photogallery.class.big, master_settings_element);
        options.slick("slickGoTo", item);
        $(App.photogallery.class.minItem, master_settings_element).removeClass("_active");
        $(this).addClass("_active");
      });
    }
  },
  anchorMenu : {
    init : function() {
      /**
       * @return {undefined}
       */
      function render() {
        var scrollTop = $(window).scrollTop();
        if (scrollTop > sectiontop && !$el.hasClass("_fix")) {
          $el.addClass("_fix");
          $("body").css({
            paddingTop : topPadding
          });
        } else {
          if (scrollTop < sectiontop && $el.hasClass("_fix")) {
            $el.removeClass("_fix");
            $("body").css({
              paddingTop : 0
            });
          }
        }
        var draggingCoord;
        $("a", $el).each(function() {
          var hash = $(this).attr("href");
          var $destination = $(hash);
          if ($destination.length) {
            sectTop = $destination.offset().top;
            if (scrollTop + $(window).height() / 2 > sectTop) {
              draggingCoord = $(this);
            }
          }
        });
        $("a", $el).parent().removeClass("_active");
        if (draggingCoord != undefined) {
          draggingCoord.parent().addClass("_active");
        }
      }
      var $el = $(".js--anchor-menu");
      if (!$el.length) {
        return;
      }
      $("a", $el).on("click", function(event) {
        event.preventDefault();
        var hash = $(this).attr("href");
        $("body, html").animate({
          "scrollTop" : $(hash).offset().top - 100
        }, 300);
      });
      var sectiontop = $el.offset().top;
      var topPadding = $el.innerHeight();
      render();
      $(document).on("scroll", function() {
        render();
      });
    }
  },
  services : {
    init : function() {
      App.services.filter();
    },
    filter : function() {
      /**
       * @return {undefined}
       */
      function next() {
        var type = $(toggle);
        var txtChannelName = $(reconnectingCallback);
        var element = $(modifiedTemplate);
        element.addClass("preloader");
        $.ajax({
          url : type.attr("action") + "?" + txtChannelName.attr("name") + "=" + txtChannelName.val(),
          type : "GET",
          data : type.serialize(),
          success : function(data) {
            element.removeClass("preloader");
            try {
              element.html(data);
            } catch (conv_reverse_sort) {
              console.log(conv_reverse_sort);
            }
          }
        });
      }
      /** @type {string} */
      var toggle = ".js--s-f-services";
      /** @type {string} */
      var reconnectingCallback = ".js--s-f-clinic";
      /** @type {string} */
      var modifiedTemplate = ".js--services-list";
      $(document).on("change", reconnectingCallback, function() {
        next();
      });
      $(document).on("change", toggle + " input", function() {
        next();
      });
    }
  },
  mobMenu : {
    init : function() {
      /**
       * @return {undefined}
       */
      function move() {
        $(ol_el).addClass("_mob-active");
        $(notificationButtonID).addClass("_active");
      }
      /**
       * @return {undefined}
       */
      function setView() {
        $(ol_el).removeClass("_mob-active");
        $(notificationButtonID).removeClass("_active");
      }
      /**
       * @param {!Object} text
       * @return {undefined}
       */
      function expect(text) {
        text.addClass("_mob-active");
      }
      /**
       * @param {!Object} image
       * @return {undefined}
       */
      function render(image) {
        image.removeClass("_mob-active");
      }
      /** @type {string} */
      var ol_el = ".js--mob-menu";
      /** @type {string} */
      var notificationButtonID = ".js--mob-menu-open";
      /** @type {string} */
      var reconnectingCallback = ".js--mob-menu-close";
      /** @type {string} */
      var element = ".js--mob-menu-item";
      /** @type {string} */
      var universalCallback = ".js--mob-menu-submenu-open";
      /** @type {string} */
      var hiCallback = ".js--mob-menu-submenu-close";
      $(document).on("click", notificationButtonID, function(event) {
        event.preventDefault();
        if (!$(this).hasClass("_active")) {
          move();
        } else {
          setView();
        }
      });
      $(document).on("click", reconnectingCallback, function(event) {
        event.preventDefault();
        setView();
      });
      $(document).on("click", universalCallback, function(event) {
        event.preventDefault();
        expect($(this).closest(element));
      });
      $(document).on("click", hiCallback, function(event) {
        event.preventDefault();
        render($(this).closest(element));
      });
    }
  },
  sticky : {
    init : function() {
      $(".js--sticky").stick_in_parent({
        offset_top : 16
      });
      // $(".h-middle").stick_in_parent({ parent: $('body'), })
    }
  },
  upBtn : {
    init : function() {
      /**
       * @return {undefined}
       */
      function render() {
        if ($(window).scrollTop() > $(window).height() && !$(titleContainer).hasClass("_active")) {
          $(titleContainer).addClass("_active");
        } else {
          if ($(window).scrollTop() < $(window).height() && $(titleContainer).hasClass("_active")) {
            $(titleContainer).removeClass("_active");
          }
        }
      }
      /** @type {string} */
      var titleContainer = ".js--up-btn";
      render();
      $(window).on("scroll", function() {
        render();
      });
      $(document).on("click", titleContainer, function(event) {
        event.preventDefault();
        $("body, html").animate({
          "scrollTop" : 0
        }, 500);
      });
    }
  },
  "fixHeightOnSubmit" : function() {
    (function() {
      $("#itechExpertMainFilter").on("submit", function() {
        $("body").height("auto");
      });
    })();
  }
};
$(function() {
  App.init();
});
