import { initModules } from './modules';
import { importAll } from './helpers'
import Rails from 'rails-ujs';

import '@fancyapps/fancybox'
import 'sticky-kit/dist/sticky-kit.js'

importAll(require.context('images/', true))
importAll(require.context('./parts/', true))

import './vendor/jquery.easytabs.js'
import './main.js'
import 'bootstrap';


window.initModules = initModules;

$(document).ready(function(){
  initModules() 
  Rails.start()
})



// var req = require.context("../images", true, /^(.*\.(png$))[^.]*$/im);
// req.keys().forEach(function(key){
//   require("../images/logo.png")
//     // console.log(key)
// });