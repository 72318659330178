import {__module} from '../modules';

__module('phones', (el, options) => {
  const chooser = $(el).find('.cchooser')
  const closeChoose = chooser.find('.cchooser__close')
  
  if(!$(".phones").hasClass('has_clinic')) {
    setTimeout(() => chooser.addClass('show'), 1000);
  }

  closeChoose.on('click', () => { chooser.removeClass('show') })
  $('.phones__choose').on('click', () => { chooser.addClass('show') })


}) 