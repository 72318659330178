import {__module} from '../modules';

import {
    jarallax,
    // jarallaxElement,
    jarallaxVideo
} from 'jarallax';

  jarallaxVideo()

__module('vparallax',  (el, options) => {
  // $(el).css({"background": "tranparent"})
  $(document).ready(function() {
    jarallax(el)
  })

})
