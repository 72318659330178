
const initModules = function() {
  $('[data-module]:not(.inited)').each(function() {
    var fn, module, options;
    if (!window.modules) window.modules = {};
    module = $(this).attr('data-module');
    options = $(this).attr('data-options');

    fn = window.modules[module];
    // console.log(window.modules, module)

    if (typeof fn === 'function') {
      if (options) {
        options = JSON.parse(options);
      } else {
        options = {}
      }
      // console.log(options);
      $(this).addClass("inited");

      fn(this, options);
    }
  });

}


const __module = function(name, fn) {
  if (!window.modules) window.modules = {};
  window.modules[name] = fn;
}



export {initModules, __module};
