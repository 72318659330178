function openPopup(el, cls, mercy) {
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  var popup = { el : $('<div class="popup"><div class="background"></div><div class="popup-center"><div class="popupScroll"><div class="popup-table"><div class="playout"></div></div></div></div>') },
    close = $('<div class="popup-close"><div class="control-button control-button_close control-button_medium"><span class="fonticon fonticon_cross-wide"></span></div><div class="popup-close__text">Закрыть</div></div>');

  if(!mercy) { // Удалить все остальные попапы перед открытием, если не указано, что их нужно пощадить
    $(".popup").remove();
  }

  if (iOS) {
    setTimeout(function() {
      popup.syncSize();
    }, 100);
  }
  
  popup.layout = popup.el.find('.playout'),
  popup.background = popup.el.find('.background');

  if (cls) {
    popup.el.addClass(cls);
  }

  popup.layout.append(el);
  $('body').append(popup.el);
  $("body").css('overflow', 'hidden');

  popup.el
    .on('mouseenter', function(){ window.scrolldisabled = true; })
    .on('mouseleave', function(){ window.scrolldisabled = false; });

  popup.syncSize = function() {
    popup.container = popup.el.find('.popup-container');
    popup.container.append(close);
    initModules();
    if ((popup.el.find('input').length || popup.el.find('textarea').length) && iOS) {
      $(document).scrollTop(0);
      setTimeout(function(){
        popup.el.find('input')[0].focus();
        $('body').addClass('ios');
        $('html').addClass('ios');
        popup.el.addClass('fixfixed');
      }, 100);
    }
  };

  popup.syncSize();

  popup.el.find('img').load(function(){
    popup.syncSize();
  });

  $(window).on('resize.popup', function(){
    popup.syncSize();
  });

  $(document).on("popup:close", function(){
    popup.close();
  })

  setTimeout(function(){
    popup.layout.addClass('loaded');
    popup.syncSize();
  },100);

  popup.close = function() {
    popup.el.remove();
    $("body").css('overflow', 'visible');
    $(window).unbind('.popup');
    $('body').removeClass('ios');
    $('html').removeClass('ios');
  };

  $(document).on('mouseup touchend', function(e) {
    var container = popup.el.find(".popup-cell");
    
    if (container.is(e.target) && container.has(e.target).length === 0) {
      popup.close();
      $(document).unbind('mouseup');
    }
  });

  $(document).keyup(function(e) {
    if (e.keyCode == 27) {
      popup.close();
      $(document).unbind('mouseup');
    }
  });

  close.click(function(){
    popup.close();
    $(document).unbind('mouseup');
  });

  return popup;
}


class Popup {
  constructor(){
    this.element = {
      popup: $('<div class="popup"><div class="popup__center"><div class="popup__scroll"><div class="popup__table"><div class="popup__row"><div class="popup__cell"><div class="popup__container"></div></div></div></div></div></div></div>')
    }
    this.element.container = this.element.popup.find(".popup__container")  
    this.mup = this.mup.bind(this)
  }

  mup(e) { // mouseup
    var container = this.element.popup.find(".popup__cell");
    if (container.is(e.target) && container.has(e.target).length === 0) {
      this.close();
    }
  }  
  
  open(data) {
    $("body, .menu").css('overflow', 'hidden');
    this.element.container.append(data)
    $('body').append(this.element.popup) 
    $(document).on('mouseup touchend', this.mup);

  }

  close() {
    this.element.popup.remove();
    $("body, .menu").css('overflow', '');
    $(document).off('mouseup touchend', this.mup);
  };

}


export default Popup;
