import {__module} from '../modules';

__module('calc', (el, options) => {
  
  const input = $(el).find(".calc__input input")

  const prices = [
    [8000, 5000],
    [15000, 6000],
    [18000, 10000],
    [180000, 100000], 
  ]

  const setPrices = (price) => {
    for (var i in prices) {
      for (var j in prices[i]) {
        $(el).find('[data-id='+i+'-'+j+']').html("<div data-aos='zoom-in'>"+prices[i][j]*price+" руб."+"</div>")
      }
    }
  }

  input.on("keyup", function(e){
    const m = e.target.value.replace(/\D/g,'');
    input.val(m)
    setPrices(m)
  })

})